import React from "react"

import Layout from "../components/layout"
import {graphql} from "gatsby";
import PostLink from "../components/post-link"
import GamesMenu from "../components/games-menu";

const IndexPage = ({
                       data: {
                           allMarkdownRemark: {edges},
                       },
                   }) => {
    const Posts = edges
        .filter(edge => !!edge.node.frontmatter.date)
        .map(edge => <PostLink key={edge.node.id} post={edge.node}/>)

    return (
        <Layout pageTitle="Home">
            <GamesMenu/>

            <h2>Blog</h2>
            <ul>{Posts}</ul>
        </Layout>
    )
}

export default IndexPage

export const pageQuery = graphql`
    query {
        allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
            edges {
                node {
                    id
                    excerpt(pruneLength: 250)
                    frontmatter {
                        date(formatString: "YYYY-MM-DD")
                        slug
                        title
                    }
                }
            }
        }
    }
`