import React, {Component} from "react";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const GameCarouselItem = ({index, name, src}) => {
    const image = require('../images/' + src)

    return (
        <div key={index}>
            <img src={image}/>
            <p className="legend">{name}</p>
        </div>
    )
}

class GameCarousel extends Component {
    render() {
        const showThumbs = true
        const showArrows = true

        return (
            <Carousel
                showThumbs={showThumbs}
                showArrows={showArrows}
            >
                <div key={1}>
                    <img src={require("../images/simily-banner-full.png")}/>
                    <p class="legend">Simily</p>
                </div>
                <div key={2}>
                    <img src={require("../images/puttin-around-banner-full.png")}/>
                    <p className="legend">Puttin' Around</p>
                </div>

            </Carousel>
        )
    }
}

const GamesMenu = ({}) => {
    return (
        <GameCarousel/>
    )
}

export default GamesMenu