import React from "react"
import {Link} from "gatsby"

//
// stub for blog posts, displayed on the main page.
//
const PostLink = ({post}) => (
    <div>
        <Link to={post.frontmatter.slug}>
            {post.frontmatter.title} ({post.frontmatter.date})
        </Link>
    </div>
)

export default PostLink